$primary-color: #4B4F54 !default;
$secondary-color: #E56A54 !default;
$primary-background-color: white !default;
$secondary-background-color: #D9D9D6 !default;
$tertiary-background-color: #A4DBE8 !default;

$button-background-color: $primary-background-color !default;
$info-header-color:$primary-color !default;
$info-header-background-color: $primary-background-color !default;
$card-slider-background: $secondary-color !default;
$main-font: 'AmericanTypewriter', serif !default;
$heading-font: 'Franchise', sans-serif !default;
$nav-font: $heading-font !default;
$italic-font: $main-font !default;
$button-font:$main-font !default;
$floorplan-featured-color: $primary-color !default;
$floorplan-hover-color: $primary-background-color !default;
$floorplan-hero-featured-color: $primary-color !default;
@import "~@renaissancerentals/renaissance-component/lib/assets/variables";
