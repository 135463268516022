@import "src/assets/variables";
.logo {
  img {
    height: 3rem;
  }
  @media (min-width: $medium-size) {
    img {
      height: 5rem;
    }
    button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}