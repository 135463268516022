@font-face {
  font-family: 'AmericanTypewriter';
  src: url('./fonts/AmericanTypewriterMedium.ttf');
}

@font-face {
  font-family: 'Franchise';
  src: url('./fonts/Franchise-Regular.ttf');
}

@font-face {
  font-family: 'AmericanTypewriterLight';
  src: url('./fonts/AmericanTypewriterLight.ttf');
}

@import "~@renaissancerentals/renaissance-component/lib/assets/App";

main {
  .section-property-location {
    h3 {
      color: $secondary-color;
      font-size: 1.3rem;
    }

    p {
      font-size: .8rem;

      i {
        font-family: $main-font;
        font-size: .8rem;
      }
    }

    a {
      color: $primary-color;
      cursor: pointer;
      text-decoration: underline;
    }

    .property--aside {
      color: $section-heading-color;
    }
  }

  .muncher-badge {
    background-color: $secondary-background-color;

    p {
      color: $primary-color;
    }

    .muncher-button {
      p {
        color: $primary-background-color;
      }
    }

    .selected {
      color: $primary-color;
    }
  }

  .section-card {
    color: $primary-color;

    .card-header {
      background-color: $primary-color;
    }

    .muncher-button--tertiary {
      background-color: $secondary-background-color;
      color: $primary-color;
    }
  }

}
